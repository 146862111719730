import { PaymentDetailsStatus } from '@globals';
import { Item } from '@ui/SimpleCombobox';
import { LightBotsQuery_lightBots } from '@utils/Data/Bot/@types/LightBotsQuery';
import Maybe from 'graphql/tsutils/Maybe';

export type BotListType = LightBotsQuery_lightBots & {
  workspace_id: Maybe<string>;
};

export enum ComboboxType {
  item,
  footer,
  spacer,
  workspaceHeader,
  botsHeader,
  linkToAwesomePanel,
}

export interface ComboboxItemBotType extends Item {
  type: ComboboxType.item;
  bot: BotListType;
}

export interface ComboboxAllBotsType extends Item {
  type: ComboboxType.footer;
}

export interface ComboboxHeaderType extends Item {
  type: ComboboxType.workspaceHeader;
  status: PaymentDetailsStatus;
}

export interface ComboboxBotsHeaderType extends Item {
  type: ComboboxType.botsHeader;
}

export interface ComboboxSpacerType extends Item {
  type: ComboboxType.spacer;
}

export interface ComboboxLinkAwesomePanelType extends Item {
  type: ComboboxType.linkToAwesomePanel;
}

export type ComboboxItemType =
  | ComboboxItemBotType
  | ComboboxAllBotsType
  | ComboboxHeaderType
  | ComboboxBotsHeaderType
  | ComboboxSpacerType
  | ComboboxLinkAwesomePanelType;
